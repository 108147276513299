<template>
  <b-card-body>
    <b-card-text>
      <!--
    e.g. tableData={
        "head" : ["col 1 header", "col 2 header"],
        "body": [
        ["Cell 1,1", "Cell 1,2"],
        ["Cell 2,1", "Cell 2,2"]
        ]
    }
    -->
      <table class="table">
        <thead>
          <tr>
            <th scope="col" v-for="header in tableData.head" :key="header">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in tableData.body" :key="rowIndex">
            <td v-for="(cell, colIndex) in row" :key="colIndex">{{ cell }}</td>
          </tr>
        </tbody>
      </table>
    </b-card-text>
  </b-card-body>
</template>
<script>
export default {
  name: 'TableComponent',
  props: ['tableData'],
};
</script>

<template>
  <div>
    <!-- Modal -->
    <b-modal id="creationEventModal" title="Event" scrollable centered ok-only size="xl">
      <highlight language="xml">{{ creationEventData }}</highlight>
    </b-modal>

    <div class="row">
      <div class="col-lg-4 py-1">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white">GTIN</span>
          </div>
          <input id="gtin" name="gtin" class="form-control" required="required" v-model="gtin" style="min-width:150px"
                 @keyup.enter="query(gtin, serialization)">
        </div>
      </div>
      <div class="col-lg-4 py-1">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white">Lot</span>
          </div>
          <input id="serialization" name="serialization" class="form-control" required="required"
                 v-model="serialization" style="min-width:50px" @keyup.enter="query(gtin, serialization)">
        </div>
      </div>
      <div class="col-lg-2 py-1">
        <b-button name="submit" type="button" class="btn-custom btn-block" @click="query(gtin, serialization)"> Search
        </b-button>
      </div>
      <div class="col-lg-2 py-1">
        <b-button name="reset" type="button" class="btn-custom btn-block" @click="reset"> Reset</b-button>
      </div>
    </div>
    <div v-if="event != null && Object.keys(event).length > 0" class="accordion my-4" role="tablist">
      <b-card no-body class="mb-1" v-for="(category, index) in event" :key="Object.keys(category)[0]">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button variant="info" block v-b-toggle="'accordion-' + index">{{ Object.keys(category)[0] }}</b-button>
        </b-card-header>
        <b-collapse :id="'accordion-' + index" accordion="role_attributes" role="tabpanel"
                    :visible="index === 0 ? 'true' : 'false'">
          <ListComponent v-if="Array.isArray(category[Object.keys(category)[0]])"
                         :items="category[Object.keys(category)[0]]"
                         v-on:loadCreationEvent="loadCreationEvent($event)"></ListComponent>
          <TableComponent v-else :tableData="category[Object.keys(category)[0]]"></TableComponent>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import ListComponent from '@/components/ListComponent';
import TableComponent from '@/components/TableComponent';
import {mapGetters} from 'vuex';
import hljs from 'highlight.js'
import Highlight from 'vue-highlight-component'

hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'))

export default {
  name: 'overview',
  components: {
    ListComponent,
    TableComponent,
    Highlight
  },
  data: function () {
    return {
      gtin: this.$route.params.gtin,
      serialization: this.$route.params.ser,
      event: {},
      creationEventData: 'Loading...',

      lastQuery: {
        gtin: '',
        serialization: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    })
  },
  watch: {
    isAuthenticated: function () {
      if (this.lastQuery.gtin && this.lastQuery.serialization) {
        this.query(this.lastQuery.gtin, this.lastQuery.serialization);
      }
    }
  },
  methods: {
    loadCreationEvent: function (lgtin) {
      this.creationEventData = 'Loading...';

      this.$axios
        .get('/api/creation_event/' + lgtin)
        .then(response => {
          this.creationEventData = response.data;
        })
        .catch(e => {
          this.flashMessage.error({
            title: 'Error getting event data',
            message: 'Could not get event data for lgtin ' + lgtin + '. ' + e.data
          });
        });
    },
    query: function (gtin, serialization) {
      if (!this.validateInputs(gtin, serialization)) {
        this.flashMessage.setStrategy('single');
        this.flashMessage.info({
          title: 'Required field missing',
          message: 'Both fields are required. Please check your inputs',
          icon: '/images/R-Cycle_Logo_RGB_300.png',
          clickable: true,
          time: 10000,
          padding: 500
        });
        return;
      }

      this.$axios
        .get('/internal/gtin/' + gtin + '/lot/' + serialization)
        .then(response => {
          this.event = response.data;
        })
        .catch(e => {
          switch (e.response.status) {
            case 400:
              this.flashMessage.error({
                title: 'Error message',
                message: 'An error has occurred. Check your entries and try again.'
              });
              break;

            case 404:
              this.flashMessage.error({
                title: 'Not Found',
                message: 'No data could be found for the Reel ID ' + gtin + '.' + serialization
              });
              break;

            default:
              this.flashMessage.error({
                title: 'Error Message',
                message: 'Service is not available.'
              });
          }
        });

      this.lastQuery = {
        gtin: gtin,
        serialization: serialization
      };

      const queryUrl = '/gtin/'+ gtin + '/lot/' + serialization;
      if (this.$route.path !== queryUrl) {
        this.$router.push(queryUrl);
      }

    },
    validateInput: function () {
      return this.gtin && this.serialization;
    },
    validateInputs: function (gtin, serialization) {
      return gtin && serialization;
    },
    reset: function () {
      this.event = {};
      this.gtin = '';
      this.serialization = '';

      let path = '/';
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    }
  },
  beforeMount() {
    if (this.validateInput()) {
      this.query(this.gtin, this.serialization);
    }
  }
};
</script>

<style scoped>
.btn-custom {
  color: #000000;
  background-color: #FFFFFF;
  border-color: #5794c4;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}
</style>

<!-- Don't forget to include a preferred theme -->
<style src="highlight.js/styles/github.css"></style>

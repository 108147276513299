<template>
<b-card-body>
  <b-card-text>
    <ul class="list-group list-group-flush">
      <li class="list-group-item text-left" v-for="(value, key) in items" :key="key">
        <div class="row">
          <div class="col-6">
            {{ Object.keys(value)[0] }}
          </div>
          <div class="col-6" v-if="
                Array.isArray(value[Object.keys(value)[0]]) &&
                Object.prototype.toString.call(
                  value[Object.keys(value)[0]][0]
                ) === '[object Object]'
              ">
            <ul class="list-group list-group-flush">
              <li class="list-group-item text-left" v-for="(subVal, subKey) in value[Object.keys(value)[0]]" :key="subKey">
                <div class="row">
                  <div class="col-6 pl-0">
                    {{ Object.keys(subVal)[0] }}
                  </div>
                  <div class="col-6">
                    {{ subVal[Object.keys(subVal)[0]] }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6" v-else-if="Array.isArray(value[Object.keys(value)[0]])">
            <ul class="list-group list-group-flush">
              <li class="list-group-item text-left" v-for="(subItem, index) in value[Object.keys(value)[0]]" :key="index">
                <div class="row">
                  <div class="col pl-0">
                    {{ subItem }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6" v-else-if="Object.keys(value)[0] === 'Creation Event'">
            <b-button v-on:click="$emit('loadCreationEvent', value[Object.keys(value)[0]])" v-b-modal.creationEventModal>
              Show event
            </b-button>
          </div>
          <div class="col-6" v-else>
            {{ value[Object.keys(value)[0]] }}
          </div>
        </div>
      </li>
    </ul>
  </b-card-text>
</b-card-body>
</template>
<script>
export default {
  name: 'TableComponent',
  props: ['items'],
};
</script>
